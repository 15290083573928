<template>
  <div>
    <arq-previews-page />
    <h1 class="ml-4">
      Generar Cupón
    </h1>
    <form
      class="w-50 lg:w-25 mx-auto my-4"
      @submit.prevent="confirm()"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <b-form-input
              v-else
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
              :min="f.min"
              :max="f.max"
            />
          </div>
          <div v-if="f.type === 'select-services'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="optionsServices"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <div v-if="contratos.length > 0">
              <b-form-select
                v-model.number="data[f.key]"
                value-field="idProducto"
                text-field="descservicio"
                :options="contratos"
              />
            </div>
            <div v-else>
              <b-form-input
                v-model="data[f.key]"
                value-field="idProducto"
              />
            </div>
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            v-model="data[f.key]"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <b-button
          type="submit"
          variant="primary mt-3"
        >
          Confirmar
        </b-button>
      </div>
    </form>
    <div class="text-center">
      <h4 class="w-auto">
        Valor total: <b>{{ total | toCurrency }}</b>
      </h4>
      <h4
        v-if="balance > 1"
        class="w-auto"
      >
        Valor minimo sugerido: <b>{{ suggested | toCurrency }}</b>
      </h4>
    </div>
    <ArqPdf
      v-if="pdfBase64"
      :no-print="true"
      class=" mx-auto w-50 "
      :src="pdfBase64"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex/'

export default {
  data() {
    return {
      testvar: process.env.VUE_APP_VERSION,
      currentPage: 0,
      items: [],
      pageCount: 0,
      data: {},
      total: null,
      pdfBase64: null,
      process: 'descargar-duplicados',
      title: 'Descarga de Duplicados',
      procedure: '/client/coupon/generate',
      method: 'get',
      facturas: [],
      suggested: null,
      form: [
        {
          label: 'Contrato',
          key: 'idContract',
          type: 'select-contract',
          required: true,
        },
        {
          label: 'Valor: $',
          key: 'value',
          type: 'number',
          required: true,
        },
      ],
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      balance: '',
    }
  },
  watch: {
    'data.idContract': function () {
      this.pdfBase64 = null
      this.sendGetProcedure('/coupon/suggested', this.data).then(res => {
        if (res.data.object) {
          if (res.data.object.balance === 1) {
            this.data.value = res.data.object.total
          } else {
            this.data.value = res.data.object.suggested
          }
          this.suggested = res.data.object.suggested
          this.balance = res.data.object.balance
          this.total = res.data.object.total
        }
      })
    },
    suggested(val) {
      if (val > 0) {
        this.form[2].disabled = false
        this.form[2].min = val
      } else {
        this.form[2].disabled = true
      }
    },
  },
  computed: {
    ...mapState('client', ['contratos']),
  },
  methods: {
    confirm() {
      this.pdfBase64 = ''
      this.sendPostProcedure('/coupon/generate', this.data).then(res => {
        if (res.data.code === 0) {
          this.$swal(
            'Cupon generado extiosamente.',
            'Haz clic en el boton para continuar.',
            'success',
          )
          if (res.data.object) {
            this.pdfBase64 = `data:application/pdf;base64,${res.data.object}`
          }
        } else {
          this.$swal('Error.', res.data.msg, 'error')
        }
      })
    },
    async sendGetProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .get(`/client${procedure}`, { params: formData })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async sendPostProcedure(procedure, formData) {
      // const data = JSON.stringify(formData)
      return new Promise((resolve, reject) => {
        axios
          .post(`/client${procedure}`, formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>

<style></style>
